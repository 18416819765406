<template>
  <div class="board_comment">
    <table cellspacing="0" :summary="`${$t('inputCmt')}`" v-if="delYn != 'Y'">
    <caption>{{$t('postCommet')}}</caption>
      <colgroup>
        <col width="5">
        <col width="">
        <col width="5">
      </colgroup>
      <tbody>
        <tr>
          <td class="dr01"></td>
          <td class="dr02"></td>
          <td class="dr03"></td>
        </tr>
        <tr>
          <td class="dr04"></td>
          <td class="text">
            <dl class="info">
              <dt>
                <label for="crtUser">{{$t('nm')}}</label> :
              </dt>
              <dd>
                <input type="text" size="10" class="board_input" v-model="commInfo.crtUser" maxlength="50" >
              </dd>
              <dt>
                <label for="c_pwd">{{$t('pw')}}</label> :
              </dt>
              <dd>
                <input type="password" size="10" class="board_input" v-model="commInfo.pw" autocomplete="off" maxlength="256">
              </dd>
              <dt>
                <label for="c_codechk">{{$t('autoText')}}</label> : 
              </dt>
              <dd>
                <img :src="require(`@/assets/img/autoimg/0${commInfo.autoimgStr}.png`)" height="30" :alt="$t('rejectJoin')" class="img_center" align="absmiddle">
                <input type="text" size="10" v-model="commInfo.codechk" class="board_input" maxlength="6" :title="$t('inputLeft')" >
              </dd>
            </dl>
          </td>
          <td class="dr06"></td>
        </tr>
        <tr>
          <td class="dr04"></td>
          <td class="dr05">
            <textarea cols="50" rows="3" style="vertical-align:top;" :title="$t('inputComm')" v-model="commInfo.commCtnt" maxlength="1000" ></textarea>
            <img @click="doPostCommSave('N')" @keydown="doKeydownCommSave($event, 'N')" src="@/assets/img/board/nninc_simple/img/dr_bt.gif" :alt="$t('writeCmt')" >
          </td>
          <td class="dr06"></td>
        </tr>
        <tr>
          <td class="dr07"></td>
          <td class="dr08"></td>
          <td class="dr09"></td>
        </tr>
      </tbody>
    </table>
    <ul class="cmt_list" v-for="(item, index) in commList" :key="index">
      <li :style="{marginLeft: `${(parseInt(item.commDepth)*15)}px`}">
        <div :class="item.commDepth == '0'?'h':'hh'">
          <div v-if="item.delYn=='N'">
            <strong>{{item.crtUser}}</strong> [{{item.crtDttm}}] 
          </div>
          <div>
            <strong v-if="item.delYn=='Y'">{{$t('message.commDelUser')}}</strong>
          </div>
          <div style="float: right;" v-if="((item.crud == null || item.crud == '') && item.delYn != 'Y')">
            <img v-if="item.commDepth < 2" @click="doComm(item)" @keydown="doKeydownComm($event, item)" src="@/assets/img/board/nninc_simple/img/write_bt.gif" :alt="$t('writeCmt')" >
            <img @click="doPostCommModify(item)" @keydown="doKeydownCommModify($event, item)" src="@/assets/img/board/nninc_simple/img/modify_bt.gif" :alt="$t('modifyCmt')" >
            <img @click="doPostCommDel(item)" @keydown="doKeydownCommDel($event, item)" src="@/assets/img/board/nninc_simple/img/delete_bt.gif" :alt="$t('deleteCmt')" >
          </div>
          <div style="float: right;" v-if="item.crud == 'U' || item.crud == 'D'">
            <img @click="doPostCommSave(item.crud, item)" @keydown="doKeydownCommSave($event, item.crud, item)" src="@/assets/img/board/nninc_simple/img/command_ok.gif" width="34" height="20" :alt="$t('confirm')" >
            <img @click="doPostCommCancle(item)" @keydown="doKeydownCommCancle($event, item)" src="@/assets/img/board/nninc_simple/img/command_cancel.gif" width="34" height="20" :alt="$t('cancel')" >
          </div>
          <div v-if="item.crud == 'U' || item.crud == 'D'">
            {{$t('pw')}} : <input type="password" size="10" class="board_input" v-model="item.pw" autocomplete="off" maxlength="256" > 
          </div>
          <div v-if="item.delYn=='N'">
            <p v-if="(item.crud == null || item.crud == '') || item.crud == 'D' || item.crud == 'R'" style="word-wrap: break-word;">{{item.commCtnt}}</p>
          </div>
          <div v-if="item.delYn=='Y'">
            <p>{{$t('message.commDelCtnt')}}</p> 
          </div>
          <textarea v-if="item.crud == 'U'" cols="50" rows="3" style="vertical-align:top;" :title="$t('inputComm')" v-model="item.commCtnt" maxlength="1000" ></textarea>
          <div v-if="item.crud=='R'" style="width: 100%; background-color: #FAFAFA; margin-bottom: 2px; padding-top: 7px;">
            <div style="padding-left: 15px;">
              {{$t('nm')}} : <input type="text" size="10" class="board_input" v-model="commReplayInfo.crtUser" maxlength="50" >
              {{$t('pw')}} : <input type="password" size="10" class="board_input" v-model="commReplayInfo.pw" autocomplete="off" >
              {{$t('autoText')}} : <img :src="require(`@/assets/img/autoimg/0${commReplayInfo.autoimgStr}.png`)" height="30" :alt="$t('rejectJoin')" class="img_center" align="absmiddle">
              <input type="text" size="10" id="c_codechk" name="c_codechk" v-model="commReplayInfo.codechk" class="board_input" maxlength="6" :title="$t('inputLeft')" >
              <img @click="item.crud=''" @keydown="doKeydownReplayCancle($event, item)" src="@/assets/img/board/nninc_simple/img/dr_del_bt.gif" :alt="$t('cancel')" style="float: right; width: 17px; margin-right: 11px;" >
            </div>
            <div style="padding-top: 6px; padding-left: 15px; height: 58px; text-align: left; line-height: 140%;">
              <textarea cols="50" rows="3" style="vertical-align: top;width: 88%;height: 38px;" :title="$t('inputComm')" v-model="commReplayInfo.commCtnt" maxlength="1000" ></textarea>
              <img @click="doCommReplayAdd(item)" @keydown="doKeydownReplayAdd($event, item)" src="@/assets/img/board/nninc_simple/img/dr_bt.gif" :alt="$t('writeCmt')" >
            </div>
          </div>
        </div>
      </li>
      <li class="line"></li>
    </ul>
  </div>
</template>

<script>
import { nullCheck } from '@/libs/comm/util';

export default {
  props: {
    delYn: {
      type: String
    },
    board: {
      type: Object,
      default: () => new Object()
    },
    tabindex: {
      type: Number,
      default: 1
    }
  },
  created() {
    this.doCommList()
  },  
  computed: {
    postNo(){
      return this.$route.query?.postNo;
    },
  },
  watch: {
    postNo(newVal) {
      if(newVal != null && newVal != '') {
        this.doCommList()
      }
    }
  },
  data() {
    return {
      searchParam: {
        contentNo: '',
        postNo: '',
      },
      commList: [],
      commReplayInfo: {
        crud: 'N',
        commNo: '',
        contentNo: '',
        accCd: '',
        postNo: '',
        commCtnt: '',
        crtUser: '',
        pw: '',
        originCommNo:0,
        commDepth:0,
        commLevel:0,
        autoimgStr: 1,
        codechk: '',
      },
      commSaveData: {
        crud: 'N',
        contentNo: '',
        postNo: '',
        pw: '',
        ip: '',
        commCtnt: '',
        crtUser: '',
        autoimgStr: 1,
        codechk: '',
      },
      commInfo: {
        crud: 'N',
        contentNo: '',
        postNo: '',
        pw: '',
        ip: '',
        commCtnt: '',
        crtUser: '',
        autoimgStr: 1,
        codechk: '',
      },
      curCommItem: {
        crud: 'N',
        contentNo: '',
        postNo: '',
        pw: '',
        ip: '',
        commCtnt: '',
        crtUser: '',
        autoimgStr: 1,
        codechk: '',
      },
    }
  },
  methods: {
    doPostCommSave(crud, item) {
      if(this.board?.boardNo == null || this.board?.boardNo == '') {
        this.commInfo.contentNo =this.$route.query?.contentNo;
      }else{
        this.commInfo.contentNo = this.board?.boardNo;
      }

      this.commInfo.postNo = this.postNo

      if(crud == 'N') {
        if(nullCheck(this.commInfo.crtUser, this.$t('nm'))) {
          return;
        }else if(nullCheck(this.commInfo.pw, this.$t('pw'))) {
          return;
        }else if(nullCheck(this.commInfo.codechk, this.$t('autoText'))) {
          return;
        }else if(nullCheck(this.commInfo.commCtnt, this.$t('ctnt'))) {
          return;
        }
      }

      if(crud == 'U' || crud == 'D') {
        if(nullCheck(item.pw, this.$t('pw'))) {
          return
        }
        this.commSaveData = item
        this.commSaveData.contentNo = item.boardNo
      }else {
        this.commSaveData = {...this.commInfo}
      }
      
      this.$tran('/site/external/contents/savePostComm', this.commSaveData).then(response => {
        this.$nextTick(() => {
          this.doCommList()
          this.commInfo = {
            crud: 'N',
            contentNo: '',
            postNo: '',
            pw: '',
            ip: '',
            commCtnt: '',
            crtUser: '',
            autoimgStr: 1,
            codechk: '',
          }
        })
      }).catch(e => {
        return
      })
    },
    doCommList() {
      if(this.board?.boardNo == null || this.board?.boardNo == '') {
        this.searchParam.contentNo = this.$route.query?.contentNo;
      }else{
        this.searchParam.contentNo = this.board?.boardNo;
      }
      
      this.searchParam.postNo = this.postNo;

      this.$tran('/site/external/contents/selectPostCommList', this.searchParam).then(response => {
        this.commList = response.data.commList
        this.commInfo.autoimgStr = response.data.autoimgStr 
      })
    },
    doComm(item) {
      const randomNum = Math.floor(Math.random() * 5 + 1);
      this.curCommItem.crud=''
      item.crud = 'R'
      this.curCommItem = item
      this.commReplayInfo = {
        crud: 'N',
        commNo: '',
        contentNo: '',
        accCd: '',
        postNo: '',
        commCtnt: '',
        crtUser: '',
        pw: '',
        originCommNo:0,
        commDepth:0,
        commLevel:0,
        autoimgStr: randomNum,
        codechk: '',
      } 
    },
    doCommReplayAdd(item) {
      if(nullCheck(this.commReplayInfo.crtUser, this.$t('nm'))) {
        return;
      }else if(nullCheck(this.commReplayInfo.pw, this.$t('pw'))) {
        return;
      }else if(nullCheck(this.commReplayInfo.codechk, this.$t('autoText'))) {
        return;
      }else if(nullCheck(this.commReplayInfo.commCtnt, this.$t('ctnt'))) {
        return;
      }

      this.commReplayInfo.contentNo = this.searchParam.contentNo
      this.commReplayInfo.postNo = this.searchParam.postNo
      this.commReplayInfo.originCommNo = item.commNo
      this.commReplayInfo.commDepth = item.commDepth
      this.commReplayInfo.commLevel = item.commLevel
      
      this.$tran('/site/external/contents/savePostComm', this.commReplayInfo).then(response => {
        this.$nextTick(() => {
          this.doCommList()
        })
      }).catch(e => {
        return
      })
    },
    doPostCommModify(item) {
      item.pw = null;
      this.curCommItem = {...item}
      item.crud = 'U'
    },
    doPostCommDel(item) {
      item.crud='D';
      item.pw = null;
    },
    doPostCommCancle(item) {
      item.crud = ''
      item.commCtnt = this.curCommItem.commCtnt
    },
    doKeydownCommSave(event, crud, item) {
      if(event.keyCode == 13) {
        event.preventDefault();
        this.doPostCommSave(crud, item);
      }
    },
    doKeydownComm(event, item) {
      if(event.keyCode == 13) {
        this.doComm(item);
      }
    },
    doKeydownCommModify(event, item) {
      if(event.keyCode == 13) {
        this.doPostCommModify(item);
      }
    },
    doKeydownCommDel(event, item) {
      if(event.keyCode == 13) {
        this.doPostCommDel(item);
      }
    },
    doKeydownCommCancle(event, item) {
      if(event.keyCode == 13) {
        this.doPostCommCancle(item);
      }
    },
    doKeydownReplayCancle(event, item) {
      if(event.keyCode == 13) {
        item.crud='';
      }
    },
    doKeydownReplayAdd(event, item) {
      if(event.keyCode == 13) {
        this.doCommReplayAdd(item);
      }
    },
  },
}
</script>